// Customizable Area Start
// @ts-nocheck
import * as React from 'react';
import { Nav as NavContainer, NavItem, Button } from 'reactstrap';
import RenderLink from './RenderLink';
import './assets/css/style.css';
import withToast from "../HOC/withSnackBar.Web";
import { getStorageData } from "../../../../framework/src/Utilities";
import NavController from './NavController.web'

import { ReactComponent as ServiceIcon } from './assets/room-service-bring-plate.svg';
import { ReactComponent as CategoriesIcon } from './assets/layout-corners-dashboard-1.svg';
import { ReactComponent as Marketing } from "./assets/advertising-megaphone.svg"
export const configJSON = require('./config')
import  Image   from '../Nav/assets/eyeIcon.svg';
import { Typography } from '@material-ui/core';
const baseURL = require("../../../../framework/src/config.js").baseURL;
import * as configcat from 'configcat-js';
import { configCatClient } from "../../../../components/src/FeatureFlag";
import { ThreeDRotationSharp } from '@material-ui/icons';
// Customizable Area End
class NavComponent extends NavController {
    // Customizable Area Start
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            // @ts-ignore
            adminuser: null,
            loading: true,
            links: [
                {
                    id: 'home',
                    label: 'Home',
                    iconName: 'icon-union',
                    link: '/Home'
                },
                {
                    id: "orders",
                    label: "Orders",
                    link: "/OrderMangementList",
                    iconName: "icon-orders",
                },
                {
                    id: "categories",
                    label: "Categories",
                    link: "/CategoriessubcategoriesWeb",
                    icon: CategoriesIcon,
                },
                {
                    id: "services",
                    label: "Services",
                    link: "/ServicesManagement",
                    icon: ServiceIcon,
                },
                {
                    id: "customers",
                    label: "Customers",
                    link: "/CustomerAdminList",
                    iconName: "icon-customer",
                },
                window.location.href.includes("dev") || window.location.href.includes("stage") ?
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: "dashboard",
                            label: "Branding",
                            link: "/DashboardAdmin",
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/AdminEmailTemplates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/AdminStaticPage'
                        },
                        {
                            id: 'image',
                            label: 'Image Catalogue',
                            link: '/AdminImageCatalogue'
                        },
                        {
                            id: 'coustomer',
                            label: 'Customer Feedback',
                            link: '/AdminCustomerFeedback'
                        },
                        {
                            id: "gallery",
                            label: "Gallery",
                            link: "/Gallery",
                        },
                    ]
                } :
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: "dashboard",
                            label: "Branding",
                            link: "/DashboardAdmin",
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/AdminEmailTemplates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/AdminStaticPage'
                        },
                        {
                            id: 'image',
                            label: 'Image Catalogue',
                            link: '/AdminImageCatalogue'
                        },
                        {
                            id: 'coustomer',
                            label: 'Customer Feedback',
                            link: '/AdminCustomerFeedback'
                        },
                        {
                            id: "gallery",
                            label: "Gallery",
                            link: "/Gallery",
                        },
                    ]
                },
                {
                    id: 'business',
                    label: 'Business Settings',
                    iconName: 'icon-settings',
                    subLinks: [
                        {
                            id: "common-settings",
                            label: "Common Settings",
                            link: "/CommonSettingsAdmin",
                        },
                        {
                            id: "staff",
                            label: "Staff",
                            link: "/AdminStaff",
                        },
                        {
                            id: 'store',
                            label: 'Store details',
                            link: '/AdminStoreDetails'
                        },  
                        {
                            id: "tax",
                            label: "Taxes",
                            link: "/AdminTaxes"
                        },
                        {
                            id: 'paymentIntegration',
                            label: 'Payment',
                            link: '/AdminPaymentIntegration'
                        },
                        {
                            id: 'mobile_app',
                            label: 'Mobile App',
                            link: '/AdminMobileApp'
                        },
                    ]
                },
                    {
                        id: "marketing",
                        label: "Marketing",
                        icon: Marketing,
                        subLinks: [
                            {
                                id: "seo",
                                label: "SEO",
                                link: "/seo-checklist"
                            },
                            {
                                id: "lead generation",
                                label: "Lead Generation",
                                link: "/marketing"
                            },
                            {
                                id: "promotions",
                                label: "Promotions",
                                link: "/ShareStoreTab"
                            }
                        ]
                    },
                {
                    id: 'account',
                    label: 'Account',
                    iconName: 'icon-account',
                    subLinks: [
                        {
                            id: 'profile',
                            label: 'Profile',
                            iconName: 'icon-user',
                            link: '/sercivesspecificsetting'
                        },
                    ]
                }
            ],
            tierType: "",
            isPreviewEnable: false
        }
    }
    // Customizable Area End

    async componentDidMount() {
        // Customizable Area Start
        const token = await getStorageData("admintoken");
        const adminUser = await getStorageData("adminuser");
        const countryName = await getStorageData("countryName");
        this.featureFlag(countryName);
        if (token && adminUser) {
            this.setState({
                adminuser: JSON.parse(adminUser) || {},
                loading: false
            })
        }
        // Customizable Area End
    }

    // Customizable Area Start
    featureFlag = async (countryName: string) => {
        let identifier = this.state.buildCardID + "" + "exampleuser@builder.ai"
        const userObject = new configcat.User(
          identifier,
          "exampleuser@builder.ai",
          countryName,
          {
            "buildCardId": this.state.buildCardID,
            "Platform": "web",
            "URL": baseURL
          }
        );
        configCatClient
          .getValueAsync('previewfeatureflag', false, userObject)
          .then((value) => {
            this.setState({ isPreviewEnable: value })
          });
      }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // @ts-ignore
        const adminuser = this.state.adminuser;
        const permissions = adminuser?.data?.attributes?.permissions;
        const permissionLinks = (data: any) => {
            if (data?.id && data?.subLinks) {
                const value1 = [];
                data?.subLinks?.map((values: any) => permissions?.find(permission => {
                    if (permission === values?.id) {
                        value1?.push(values)
                        return values
                    }
                }))
                data.subLinks = value1;
                return data?.subLinks?.length ? data : undefined;
            }
            if (data) {
                const value2 = [];
                permissions?.find(permission => {
                    if (permission === data?.id) {
                        value2?.push(data)
                        return data
                    }
                })
                return value2[0];
            }

        }
        // Customizable Area End
        // @ts-ignore
        return (
            // Customizable Area Start
            <NavContainer
                className='admin-nav'
                tabs
                vertical
            >
                {permissions && this.state?.links.length &&
                    this.state?.links.map((data: any, index: number) => (
                        permissions.includes("all")
                            ? <RenderLink data={data} tierType={this.state.tierType} />
                            : (index === 0 && permissions.includes("order")) ? <RenderLink data={this.state.links[0]} tierType={this.state.tierType} />
                                : permissionLinks(data) !== undefined
                                    ? <RenderLink data={permissionLinks(data)} tierType={this.state.tierType} /> : ''
                    ))
                         }
  
            {this.state.isPreviewEnable && <a href="/preview" target="_blank" style={{ textDecoration: "none" }}>
            <Button
                variant="contained"
                style={{
                color: "#5500CC",
                border: "1px solid #5500CC",
                width: "178px",
                height: "44px",
                borderRadius: "4px",
                background: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                }}
            >
                <img
                src={Image}
                alt="icon"
                style={{
                    width: "16px",
                    height: "16px",
                }}
                />
                <Typography
                style={{
                    fontFamily: "Rubik",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#5500CC",
                }}
                >
                {configJSON.PreviewWebsite}
                </Typography>
            </Button>
            </a>}

            </NavContainer>
            // Customizable Area End
        );

    }


}
// Customizable Area Start
export const Nav = withToast(NavComponent);
// Customizable Area End